import { CSS } from '@stitches/react'
import * as Icon from 'components/DesignSystemIcons'
import ImageWithAspectRatio from 'components/ImageWithAspectRatio'
import { useRouter } from 'next/router'
import { styled } from 'styles/stitches.config'
import { UsualItem } from 'types/api'
import { StoreItem, StoreTopOrderedItem } from 'types/api/store'
import { formatMoney } from 'utils/money'
import { calculateUsualItemPrice } from 'utils/usualItems'

export const Item = styled('div', {
  width: '100%',
  marginTop: '$s',
})

export const ItemInfo = styled('div', {
  position: 'relative',
  margin: '$s 0',
})

export const ItemName = styled('div', {
  fontSize: '$body1',
  fontWeight: '$normal',
  lineHeight: '$body1',
  margin: '$xxs 0',
  height: 48,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
})

export const ItemModifiers = styled('div', {
  fontSize: '$body3',
  fontWeight: '$normal',
  lineHeight: '$body3',
  color: '$grey600',
  margin: '$xxs 0',
  minHeight: 36,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
})

export const ItemPrice = styled('div', {
  fontSize: '$body2',
  fontWeight: '$bold',
  lineHeight: '$body2',
})

export const ItemImage = styled('div', {
  borderRadius: '$xs',
  marginBottom: '$s',
  overflow: 'hidden',
  height: 'auto',
  maxHeight: 200,
  position: 'relative',
})

export const Tag = styled('span', {
  padding: '$xxxs $xs',
  fontSize: '$tagLabel',
  borderRadius: '$xxxs',
  width: 'fit-content',
})

export const NewTag = styled(Tag, {
  color: '$blue600',
  background: '$blue100',
})

export const PopularTag = styled(Tag, {
  color: '$green800',
  background: '$green100',
})

export const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const AddToCartBtn = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  borderRadius: '50%',
  background: '$black',
  color: '$white',
  cursor: 'pointer',
})

export const ItemStatus = ({ status }: { status: string | undefined }) => {
  switch (status) {
    case 'new':
      return <NewTag>New</NewTag>
    case 'popular':
    default:
      return <PopularTag>Most Popular</PopularTag>
  }
}

type NormalizedItem = {
  itemId: string
  description?: string
  name: string
  photo: string
  status?: string
  price: number
}

const normalizeItem = (item: SupportedItemTypes): NormalizedItem => {
  // StoreItem
  if ('itemId' in item) {
    return {
      itemId: item.itemId,
      description: item.description,
      name: item.name,
      photo: item.photo ?? '',
      status: item.status,
      price: item.price,
    }
  }

  // StoreTopOrderedItem
  if ('menuItemId' in item) {
    return {
      itemId: item.menuItemId as string,
      name: item.name,
      photo: item.image,
      price: item.price,
    }
  }

  // UsualItem
  if ('choices' in item) {
    const price = calculateUsualItemPrice(item)

    return {
      itemId: item.id,
      name: item.name,
      photo: item.photo,
      price,
    }
  }

  return item
}

type SupportedItemTypes =
  | (Pick<StoreItem, 'description' | 'itemId' | 'name' | 'photo' | 'status'> & {
      price: number
    })
  | UsualItem
  | StoreTopOrderedItem

type ItemCardProps = {
  item: SupportedItemTypes
  storeId: string
  css?: CSS
}

export const ItemCard = ({ item, storeId, css }: ItemCardProps) => {
  const router = useRouter()
  const normalizedItem = normalizeItem(item)

  const handleClickItem = () => {
    router.push({
      pathname: '/explore/stores/[storeId]/[itemId]',
      query: {
        storeId,
        itemId: normalizedItem.itemId,
      },
    })
  }

  return (
    <Item css={css} onClick={handleClickItem}>
      <ItemImage>
        {normalizedItem.photo ? (
          <ImageWithAspectRatio
            aspectRatio={60}
            alt={normalizedItem.name}
            src={normalizedItem.photo}
          />
        ) : (
          <img src="/images/menu/hot-drinks.png" alt={normalizedItem.name} />
        )}
      </ItemImage>
      <ItemInfo>
        <ItemStatus status={normalizedItem.status} />
        <ItemName>{normalizedItem.name}</ItemName>
        <Container>
          <ItemPrice>{formatMoney(normalizedItem.price)}</ItemPrice>
          <AddToCartBtn>
            <Icon.Plus size={12} />
          </AddToCartBtn>
        </Container>
      </ItemInfo>
    </Item>
  )
}
